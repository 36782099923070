body{
    max-width: 100vw;
    min-height: 100vh;
}

.headernav {
    align-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    justify-content: center;
    max-width: 100vw;
}

#charts {
   background-color: transparent !important;
   border: none;
   box-shadow: none;
   font-size: 20pt;
}

#chartslink {
    text-decoration: none;
}

#charts:hover {
    transform: scale(1.3);
}

#hlinks {
    background: transparent;
}

#accountel {
    background: #006633 !important;
    color: white !important;
}

#unstake3 {
    display: none !important;
}

#web3-status-connected {
    background: #FFA400 !important;
    color: white !important;
    border: none !important;
}

#important {
    color: #FFA400 !important;
    font-weight: bold;
}

#coinlogo {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    padding: 30px 0;
   
}

#swap-nav-link {
    background: transparent;
    font-size: 110%;
}

#unstake {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    border: 2px solid #006633!important;
}

#unstakenewpool {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    border: 2px solid #006633 !important;
}

#claim {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    border: 2px solid #006633 !important; 
}

#notice {
    text-align: center;
    margin: 0 1rem;
    padding-bottom: 2rem;
    line-height: 150%;
}

#stake {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
}

#pool-nav-link {
    background-color: transparent !important;
    font-size: 110%;
}

#navbarel {
    background: transparent !important;
}

#moonbeam {
    background: transparent !important;
}

#earn-nav-link {
    background: transparent;
    font-size: 110%;
}

#swap-nav-link:hover {
    color: white !important;
}

#pool-nav-link:hover {
    color: white !important;
}

#earn-nav-link:hover{
    color: white !important;
}   

#logo {
    width: 10%; margin-left: auto; margin-right: auto; text-align: center; justify-content: center; min-width: 100vw; margin-top: 3rem; padding-top: 2rem;
}

#text {
    padding: 2rem;
}

#swap-currency-input2 {
    margin-bottom: 2rem;
}

#rewards-to-claim {
    padding-top: 1rem;
    text-align: center;
}

#apr {
    padding: 1rem;
    text-align: center;
}

#ban-staked {
    text-align: center;
}

.KUBTK > div > div {
    color: white !important;
}

.KUBTK > div > div > div {
    color: #FFA400 !important;
}

.logo {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    padding-left: 33rem;
    margin-top: 1.5rem;
}
@media only screen and (max-width: 1024px) {
.logo {
    align-content: center;
    padding-left: 0%;
    margin-left: 0 !important;
    margin-right: auto;
    text-align: center;
    justify-content: center;
}
}

@media only screen and (max-width: 600px) {
    .headernav {
        align-content: center;
        padding-left: 0%;
        margin-left: 0 !important;
        margin-right: auto;
        text-align: center;
        justify-content: center;

    }

    .logo {
        align-content: center;
        padding-left: 10%;
        margin-left: 0 !important;
        margin-right: auto;
        text-align: center;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    #imglogo {
        max-width: 300px;
    }
  }

#swap-currency-input2 {
    min-width: 100%;
}

.ACTIVE {
    color: #FFA400 !important;
    font-weight: bolder !important;
}

#footer {
    color: #FFA400 !important;
    font-size: 110%;  
    font-weight: bold;
    text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
    text-decoration: none;
    margin-top: 1rem;
}

#footer:hover {
    color: white !important;
}

#warning {
    color: #FFA400 !important; 
}